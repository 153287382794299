#support-track {
    background-color: #000;
    color: #B7B7B7;
}

.login-pythian-logo img {
    width: 150px;
    height: 49px;
}

#password_strength_background[data-score] {
    color: #333;
}

.login-password-requirements-mobile-section,
.login-password-requirements-mobile {
    display: none;
    line-height: 1.5;
    font-size: 10pt;
}

.login-password-requirements-mobile-section {
    margin-bottom: 20px;
    text-align: left;
}

.login-page .login-password-requirements-mobile-section p {
    font-size: 9pt;
    text-align: center;
}

.login-password-requirements-mobile-see {
    width: 100%;
    display: block;
    text-align: center;
}

.login-page {
    max-width: 1416px;
    margin: 0 auto;
    font-family: Helvetica,Arial;
    background-color: black;
}

.login-page p{
    color: inherit;
    font-size: 11pt;
    margin-bottom: 20px;
}

.curved_header {
    display: none;
}

.login-password-requirements {
    margin-top: 71px;
    padding-left: 50px;
    font-size: 11pt;
    line-height: 1.5;
}

.login-page-change-password .login-body{
    min-height: 0px;
    padding-bottom: 0px;
}

.login-page-change-password .login-form {
    margin-bottom: 40px;
}

.login-header {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 100px;
    padding-top: 12px;
}

.login-pythian-contact {
    font-size: 12pt;
    margin-right: 75px;
}

.login-pythian-logo {
    margin-left: 60px;
    margin-top: 16px;
}

.login-body {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    max-width: 852px;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    background-color: #333;
    border-radius: 5px;
    min-height: 506px;
    padding: 35px;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

.login-body > div {
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1;
}

.login-page h1 {
    color: #FAFAFA;
    margin-bottom: 35px;
    font-size: 13pt;
    line-height: 1.5;
}

.login-pythian {
    padding-right: 44px;
    padding-left: 5px;
    border-right: 1px solid white;
}

.login-client {
    padding-left: 42px;
}

#content {
    padding: 0;
    width: 100%;
    float: none;
}

.login-google {
    background-color: #e12f16;
    border: 0;
    color: white;
    height: 42px;
    width: 315px;
    font-size: 14pt;
    margin-bottom: 26px;
}

.login-form {
    margin-bottom: 60px;
}

.login-form > input {
    display: block;
    margin-bottom: 20px;
    width: 100%;
    height: 44px;
    padding: 10px;
    color: #333;
    font-size: 14pt;
}

.login-form > button {
    height: 45px;
    width: 170px;
    color: white;
    background-color: #0AA5B5;
    border: 0;
    font-size: 14pt;
}

.login-pythian-globe {
    width: 30px;
    height: 30px;
    padding-bottom: 2px;
    padding-right: 5px;
}

.login-page a {
    color: inherit;
    text-decoration: none;
}

.login-pythian-link {
    padding-right: 20px;
}

.login-google img {
    width: 30px;
    height: 30px;
    position: relative;
    right: 50px;
}

.login-forgot-password {
    display: block;
    font-size: 13pt;
    color: #DDD !important;
    margin-bottom: 35px;
}

.login-get-account, .login-announcements {
    display: block;
    font-size: 11pt;
    margin-bottom: 10px;
}

.login-client-inner {
    padding-left: 5px;
}

.login-sso-disabled-msg {
    background-color: #f55039;
    border: 1px solid #e12f16;
    color: white;
    padding: 10px;
    font-size: 11pt;
    margin-bottom: 20px;
    line-height: 1.3;
}

.login-status {
    text-align: center;
    min-height: 60px;
    margin-bottom: 30px;
    position: absolute;
    width: 100%;
    left: 0;
    top: 105px;
}

.login-error-msg, .login-success-msg {
    margin: 0 auto;
    max-width: 852px;
    padding: 15px;
    font-size: 12pt;
    text-align: left;
    color: white;
}

.login-error-msg {
    background-color: #f55039;
    border: 1px solid #e12f16;
}

.login-status > *:not(:last-child) {
    margin-bottom: 10px;
}

.login-success-msg {
    background-color: #87BA47;
}

.login-forgot-password-msg p {
    color: inherit;
}

.login-forgot-password-msg {
    max-width: 650px;
    margin-bottom: 30px;
}

.login-page-forgot-password .login-form>input {
    max-width: 330px;
}

.login-page-forgot-password {
    font-size: 12pt;
}

.login-page-forgot-password .login-body {
    min-height: 0px;
    padding-bottom: 20px;
}

.login-back {
    margin-left: -10px;
}

@media (max-width: 898px) {
    .login-pythian {
        border-right: 0;
        border-bottom: 1px solid #FFF;
        margin: 0 auto;
    }

    .login-body {
        -webkit-align-content: center;
            -ms-flex-line-pack: center;
                align-content: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                    -ms-grid-row-align: center;
                align-items: center;
        max-width: 500px;
        width: 95%;
        padding-top: 10px;
        margin-bottom: 100px;
    }

    .login-body>div {
        -webkit-flex: 0 0 auto;
           -moz-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 0;
    }

    .login-google {
        width: 280px;
    }

    .login-google img {
        right: 30px;
    }

    .login-header {
        -webkit-flex-direction: column-reverse;
           -moz-box-orient: vertical;
           -moz-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                    -ms-grid-row-align: center;
                align-items: center;
        margin-bottom: 25px;
    }

    .login-pythian-contact {
        margin: 0;
        padding: 0;
    }

    .login-pythian-logo {
        margin: 0;
        padding: 0;
        margin-top: 50px;
    }

    .login-pythian-logo img {
        width: 200px;
    }

    .login-client {
        padding-top: 20px !important;
    }

    .login-status {
        position: static;
        position: initial;
    }

    .login-error-msg, .login-success-msg {
        max-width: 500px;
        width: 95%;
    }

    .login-page-forgot-password .login-form>input {
        max-width: 100%;
    }

    .login-password-requirements-mobile-section {
        display: block;
    }

    .login-password-requirements {
        display: none;
    }
}

@media (max-width: 425px) {
    .login-pythian-contact {
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }

    .login-pythian-contact > *:first-child {
        margin-bottom: 10px;
    }

    .login-pythian-link {
        padding-right: 0;
    }
}
